<template>
  <div class="bodyHeader">
    <div class="left">
      <slot name="left"> 名称 </slot>
    </div>
    <div class="right">
      <slot name="right">
        <el-input style="margin-right: 20px" v-model="keyword" @keyup.enter="search">
        </el-input>
        <el-button type="primary" @click="search">搜索</el-button>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },
  mounted() {
    document.onkeydown = (e) => {
      if (e.keyCode == 13) {
        //这是delete健，当然也可以根据自己的需求更改
        this.search();
      }
    };
  },
  methods: {
    search() {
      this.$emit("search", this.keyword);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.bodyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  height: 60px;
  padding-left: 20px;
  margin-bottom: 10px;
  .left {
    width: 50%;
    font-size: xx-large;
    font-weight: 900;
  }
  .right {
    width: 38%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
